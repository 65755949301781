.App {
  text-align: center;
}

body{
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}



.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.share{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: fit-content;
  background-color: #f1674a;
  color: white;
  border-radius: 25px;
  padding: 15px 5px;
  font-weight: bold;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.1), 1px 1px 15px rgba(0,0,0,0.1);
  border: solid 1px #f1674a ;
}

.imgM{
margin-right: 10px;
  width: 26px;
  height: 26px;
}

.share:active{

  transform: scale(0.95);

}